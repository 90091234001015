.container.blog_container {
    width: 40%;
    padding-bottom: 4rem;
}

.blog_image {
    width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    /*border: 0.4rem solid var(--color-primary-variant);*/
}

.blog_post {
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.blog_summary {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
}

link {
    position: fixed;
    left: 3rem;
}



/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================ */
@media screen and (max-width: 1024px) {
    .container.blog_container {
        width: 60%;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================ */
@media screen and (max-width: 600px) {
    .container.blog_container {
        width: var(--container-width-sm);
    }

    .blog_summary {
        width: var(--container-width-sm)
    }
}