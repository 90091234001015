@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

/* Declare CSS variables */
:root {

    /* ======== NEON THEME ======== */
    --color-bg: #00000a; /* Dark near-black background */
    --color-bg-variant: #181818; /* Dark gray background variant */
    /*--color-primary:#00f5d4 ; !* Neon cyan for primary accents *!*/
    --color-primary:#c22085 ; /* Neon pink for primary accents */
    --color-primary-variant: #39ff14; /* Transparent neon green */
    --color-secondary: #c22085; /* Neon pink for secondary accents */
    --color-white: #f1f1f1; /* Very light gray for text */
    --color-light: #a1a1a1; /* Light gray for secondary text */


    --transition: all 200ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar{
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    background-image:url(../src/assets/bg-texture.png);
}

/* ================ GENERAL STYLES ================ */
.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

h5 {
    font-size: 1.2rem;
}

section{
    margin-top: 8rem;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition)
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================ */
@media screen and (max-width: 1024px){
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================ */
@media screen and (max-width: 600px){

    .container {
        width: var(--container-width-sm);
    }

    section > h2 {
        margin-bottom: 2rem;
    }
}