/*.home_link {*/
/*    display: none;*/
/*}*/

.home_link{
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    padding: 0.5rem 0.5rem;
    font-size: 1.25rem;
}

.blog_header {
    padding: 0.5rem 1rem 4rem;
}

.blog_header > h5 {
    text-align: center;
    color: var(--color-light);
}

.blog_header > h2 {
    text-align: center;
    color: var(--color-primary);
    margin-bottom: 1rem;
}

.blog_card_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.blog_item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.blog_item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.blog_item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.blog_item h3 {
    margin: 1.2rem 0 2rem;
}

.blog_item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}


/* Modal Styling blog_post_modal.css*/
.overlay {
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}

.modalContainer {
    max-width: 800px;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    background-color: #a1a1a1;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
    padding: 1rem;
    border: 2px solid var(--color-primary-variant);
    border-radius: 1rem;
    max-height: 80vh;
    overflow-y: auto;
    font-size: 1rem;
}

.modalContainer h1 {
    font-size: 1.5rem;
    color: var(--color-secondary);
    margin-bottom: 2rem;
}

.modalContainer p {
    color: var(--color-bg);
    text-align: left;
}

.modalRight {
    width: 100%;
}

.closeBtn {
    background-color: #a1a1a1;
    position: fixed;
    width: 30px;
    height: 30px;
    padding: 0;
    top: 10px;
    right: 10px;
    text-align: center;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    line-height: 30px;
    border: 1px solid var(--color-bg);
    border-radius: 0.25rem;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 3rem;
    padding: 1rem 2rem;
}


/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================ */
@media screen and (max-width: 1024px) {
    .home_link{
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        padding: 0.5rem 0.5rem;
        font-size: 1.25rem;
    }

    .home_link a {
        color: var(--color-primary);
    }
    .home_link a:hover {
        color: var(--color-white);
    }
    .blog_card_container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================ */
@media screen and (max-width: 600px) {
    .container.blog_card_container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .modalContainer {
        flex-direction: column;
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
        height: 100vh;
        font-size: .6rem;
    }
}