/*.home_link{*/
/*    display: none;*/
/*}*/

.home_link{
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    padding: 0.5rem 0.5rem;
    font-size: 1.25rem;
}


.portfolio_page_container h2 {
    color: var(--color-secondary);
    margin: 1.2rem 0 2rem;
}
.container.project_card_container {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr;
    gap: 1.5rem;
}

.project_item {
    width: 100%;
    background: var(--color-bg-variant);
    padding: 1rem;
    border-color: var(--color-secondary);
    border: 1px solid var(--color-secondary);
    transition: var(--transition);
}

.project_item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}


.project_item h3, p {
    color: var(--color-white);
    margin: 0 0 1rem;
}

.project_item-cta {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.project_item-cta a{
    color: var(--color-secondary);
    font-weight: 200;
    margin-right: 10px;
}


/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================ */
@media screen and (max-width: 1024px) {

    .home_link{
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        padding: 0.5rem 0.5rem;
        font-size: 1.25rem;
    }

    .home_link a {
        color: var(--color-primary);
    }
    .home_link a:hover {
        color: var(--color-white);
    }

    .portfolio_page_container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================ */
@media screen and (max-width: 600px) {
    .portfolio_page_container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .modalContainer {
        flex-direction: column;
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
        height: 100vh;
    }
}