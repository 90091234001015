header {
    height: 75vh;
    padding-top: 7rem;
    padding-bottom: 7rem;
    overflow: hidden;
}

.header_container {
    text-align: center;
    height: 100%;
    position: relative;
}

.header_container h1{
    font-family: "Sacramento", cursive;
    font-weight: 400;
    font-size: 5.5rem;
    font-style: normal;
}

.header_container h5 {
    font-size: 1rem;
}

/* ================ CTA ================ */
.cta {
    margin-top: 4rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ================ HEADER SOCIALS ================ */
.header_socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}


/* ================ SCROLL DOWN ================ */
.scroll_down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================ */
@media screen and (max-width: 1025px){
    header {
        height: 60vh;
    }

    .header_container h1{
        font-family: "Sacramento", cursive;
        font-weight: 400;
        font-size: 3rem;
        font-style: normal;
    }

}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================ */
@media screen and (max-width: 600px){
    header {
        height: 70vh;
    }

    .cta, .header_socials, .scroll_down {
        display: none;
    }
}

@media screen and (max-width: 415px){

    .header_container h5 {
        font-size: 0.65rem;
    }

    .cta, .header_socials, .scroll_down {
        display: none;
    }
}
